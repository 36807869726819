import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import projectsService from '../../../services/projectsService';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import calendarIcon from '../../../assets/icons/calendar.png';
import plus from '../../../assets/icons/Plus.png';
import { Controller, useForm } from 'react-hook-form';
import userService from 'services/userService';
import CompanyService from '../../../services/clientCompanyService';
import CooperativeCompanyService from 'services/cooperativeCompaniesService';
import SelectSearch from 'react-select-search';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Button, Box } from '@mui/material';
import uploadSimple from 'assets/icons/UploadSimple.svg';
import xCircle from 'assets/icons/XCircle.svg';
import arrowsCounterClockwise from 'assets/icons/ArrowsCounterClockwise.svg';

import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);
import createProjectValidation from 'utils/validation/registers/projects/createProjectValidation';
import { useTranslation } from 'react-i18next';
import { convertClientCompaniesToDropdownOptionsCreateProject } from 'utils/helper/clientCompanyHelper';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import { handleFilter } from 'utils/helper/filterDropdown';

import workersService from 'services/wokersService';
import { convertUserConfigToDropdownOptions } from 'utils/helper/userConfigHelper';
import { convertErrorMessagesToObject } from 'utils/validation/validatorHelper';
import isEmpty from 'lodash/isEmpty';
import { BusinessTypeEnum, SugorokuIndexEnum, WasteSugorokuIndexEnum } from 'utils/enums';
import { getFilePreview } from 'utils/helper/file';
import moment from 'moment';
import { ProjectCreateStyles } from './styled';
import DeleteIcon from '@mui/icons-material/Delete';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { BUSINESS_TYPE_SUGORUKU } from 'utils/constants';

const ProjectCreate = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {
        register,
        control,
        handleSubmit,
        watch,
        reset,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const startDateRef: any = useRef();
    const endDateRef: any = useRef();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [imageFile, setImageFile] = useState<any>(null);
    // Configurations
    const [statuses, setStatuses] = useState<any>([]);
    const [businessTypes, setBusinessTypes] = useState<any>([]);
    const [listCompanies, setListCompanies] = useState<any>([]);
    const [listCooperatives, setListCooperatives] = useState<any>([]);
    const [listWorkers, setListWorkers] = useState<any>([]);
    const [showCooperativeField, setShowCooperativeField] =
        useState<boolean>(true);
    const [newFiles, setNewFiles] = useState<File[]>([]);
    const [currentData, setCurrenData] = useState<File>();
    const [typeFile, setTypeFile] = useState("");
    const [fileData, setFileData] = useState<string | null>();
    const [showErrorFileSize, setShowErrorFileSize] = useState('');
    const [errorTypeFile, setErrorTypeFile] = useState('');
    const [isInvalidName, setIsInvalidName] = useState(false);

    const sugoruku = Object.values(SugorokuIndexEnum).map((v) => ({
        value: v.name,
        label: v.title,
    }));
    const wasteSugoroku = Object.values(WasteSugorokuIndexEnum).map((v, i) => ({
        value: v.name,
        label: v.title,
    }));
    
    const handleStartAtChange = (date: any) => {
        setStartDate(date);
    };

    const handleEndAtChange = (date: any) => {
        setEndDate(date);
    };

    const handleUploadFile = (files) => {
        if (!files || files.length < 0) return;

        const fileObject = {
            file: files[0],
            path: getFilePreview(files[0]),
        };

        setImageFile(fileObject);
    };

    const getListConfiguration = async () => {
        try {
            const response = await userService.getListConfiguration();
            if (response !== null && response !== undefined) {
                setStatuses(
                    convertUserConfigToDropdownOptions(
                        response.configurations?.project?.statuses,
                    ),
                );
                setBusinessTypes(
                    convertUserConfigToDropdownOptions(
                        response.configurations?.project?.business_types,
                    ),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListClientCompanies = async () => {
        try {
            const response = await CompanyService.getListCompanies({
                per_page: 999,
            });
            if (response !== null && response !== undefined) {
                setListCompanies(
                    convertClientCompaniesToDropdownOptionsCreateProject(
                        response.client_companies,
                    ),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListCooperativeCompanies = async () => {
        try {
            const response =
                await CooperativeCompanyService.getListCooperativeCompanies();
            if (response) {
                setListCooperatives(
                    convertClientCompaniesToDropdownOptionsCreateProject(
                        response.cooperative_companies,
                    ),
                );
            }
        } catch (error) {
            // empty
        }
    };

    const getListWorkers = async () => {
        try {
            const response = await workersService.getListWorkers({
                per_page: 999,
            });
            if (response !== null && response !== undefined) {
                setListWorkers(
                    convertObjectToDropdownOptions(response.workers),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const handleFileUpload = async(e) => {
        let arrFiles: FileList | File[] = []
        arrFiles = Array.from(e.target.files);
        const findFilesBigger6MB = arrFiles?.filter((item) => item?.size > 6144 * 1024);
        const findFilesValid = arrFiles?.filter((item) => ((item?.type === 'image/gif' || item?.type === 'image/png' || item?.type === "image/jpeg" || item?.type === 'image/webp' || item?.type?.includes("pdf")) && item.size <= 6144 * 1024)).concat(newFiles);
        setShowErrorFileSize(findFilesBigger6MB?.length > 0 ? "6MB以上がアップロードできません。" : "")

        const findFileWrongType = arrFiles?.filter((item) => !(item?.type === 'image/gif' || item?.type === 'image/png' || item?.type === "image/jpeg" || item?.type === 'image/webp' || item?.type?.includes("pdf")));
        setErrorTypeFile(findFileWrongType?.length > 0 ? "ファイル形式が正しくありません。" : "")

        setNewFiles(findFilesValid)
    };

    const handleViewFile = async (values) => {
        try {
            setCurrenData(values);
            setTypeFile(values?.type || values?.file_type)
            const response = await fetch(values?.image_path);
            const data = await response.blob();
            const file = window.URL.createObjectURL(data);
            values?.image_path ? setFileData(file) : setFileData(URL.createObjectURL(values));
        } catch (error) {}
    };

    const renderContentPreview = () => {
        if (!fileData || !typeFile) return;
        if (typeFile?.includes("pdf")) {
            return <Worker workerUrl={`${process.env.REACT_APP_WORKER_URL}`}>
                <Viewer
                    fileUrl={fileData}
                />
            </Worker>
        } else if(typeFile?.includes("image")) {
            return <img src={fileData} alt='preview-image-ojt'></img>
        }
    }

    const handleDeleteFile = async (signed_id: string, arrFile, lastModified: number | string, index: number) => {
        if (signed_id) {
            const arrFilters = arrFile?.filter(item => item?.signed_id !== signed_id)
            setNewFiles(arrFilters)
        } else {
            if(lastModified) {
                const arrFilters = arrFile?.filter((item, idx) => !(item?.lastModified == lastModified && index === idx))
                setNewFiles(arrFilters)
                const arrFiltersSendToBE = arrFile?.filter((item, idx) => !(item?.lastModified == lastModified && index === idx) && item.lastModified)
                setNewFiles(arrFiltersSendToBE)
            }
            
        }

    }

    useEffect(() => {
        getListConfiguration();
        getListClientCompanies();
        getListCooperativeCompanies();
        getListWorkers();
        reset({
            client_company_id: '',
            cooperative_company_ids: [],
            manager_worker_id: '',
            estimate_assigned_worker_id: '',
        });
    }, []);

    useEffect(() => {
        if (
            getValues('business_type') &&
            (getValues('business_type').value ===
                BusinessTypeEnum.INDUSTRIAL_WASTE.name ||
                getValues('business_type').value ===
                    BusinessTypeEnum.REAL_ESTATE.name)
        ) {
            setShowCooperativeField(false);
            setValue('cooperative_company_ids', []);
        } else {
            setShowCooperativeField(true);
        }
    }, [watch('business_type')]);
    const onCreate = (data) => {
        if(isInvalidName) return;
        return new Promise(async (resolve, reject) => {
            const cooperative_company_ids = data?.cooperative_company_ids?.map(
                (item) => item.value,
            );
            const formData = new FormData();
            const assigned_worker_ids =
                data?.assigned_project_workers_attributes?.map((item) => ({
                    worker_id: item?.value,
                }));
            const assigned_project_sugoroku_workers_attributes =
                data?.assigned_project_sugoroku_workers_attributes?.map(
                    (item) => ({
                        worker_id: item?.value,
                    }),
                );
            if (imageFile?.file) {
                formData.append('attachment', imageFile?.file);
            }
            formData.append(
                'assigned_project_sugoroku_workers_attributes',
                JSON.stringify(
                    assigned_project_sugoroku_workers_attributes || [],
                ),
            );
            formData.append(
                'assigned_project_workers_attributes',
                JSON.stringify(assigned_worker_ids || []),
            );
            formData.append('name', data?.name);

            formData.append('client_company_id', data?.client_company_id);
            formData.append('business_type', data?.business_type?.value);
            formData.append('location_address', data?.location_address);
            formData.append('location_zipcode', data?.location_zipcode);
            formData.append('note', data?.note);
            if (data.start_at) {
                formData.append(
                    'start_at',
                    moment(data.start_at)
                        .endOf('day')
                        .utc()
                        .format('YYYY-MM-DD HH:mm:ss'),
                );
            } else {
                formData.append('start_at', '');
            }
            if (data.end_at) {
                formData.append(
                    'end_at',
                    moment(data.end_at)
                        .endOf('day')
                        .utc()
                        .format('YYYY-MM-DD HH:mm:ss'),
                );
            } else {
                formData.append('end_at', '');
            }
            formData.append(
                'estimate_assigned_worker_id',
                data.estimate_assigned_worker_id,
            );
            formData.append('manager_worker_id', data.manager_worker_id);
            // formData.append('status', statuses?.[0]?.value || '');
            formData.append('status', getValues('business_type')?.label === BUSINESS_TYPE_SUGORUKU ? sugoruku[0]?.value : wasteSugoroku[0]?.value);
            cooperative_company_ids?.forEach((item, index) =>
                formData.append(`cooperative_company_ids[]`, item),
            );
            if(newFiles && newFiles?.length > 0) {
                const fileArrs = [...newFiles];
                fileArrs?.forEach((file, i) => {
                    formData.append(`files[]`, file);
                });
            }
            await projectsService
                .createProject(formData)
                .then((response) => {
                    navigate(`/projects/detail/${response?.project?.id}/plan`);
                })
                .catch((err) => {
                    const response = err?.response;
                    if (!response || typeof response === 'undefined') {
                        window.location.href = '/login';
                        reject(err);
                    }
                    switch (response.status) {
                        case 403:
                            const messages = convertErrorMessagesToObject(
                                {
                                    client_company_id: 'Client company',
                                },
                                response.data?.error,
                            );

                            if (!isEmpty(messages)) {
                                Object.keys(messages).map((key) =>
                                    setError(key, { message: messages[key] }),
                                );
                            } else {
                                setError('name', {
                                    message: response.data?.error,
                                });
                            }
                            break;
                        default:
                    }
                    reject(err);
                });
            resolve(true);
        });
    };

    return (
        <ProjectCreateStyles>
            {fileData && (
                <Dialog
                    onClose={() => setFileData(null)}
                    sx={{
                        width: '100%',
                        '.MuiPaper-root': {
                            width: typeFile?.includes("pdf") ? "100%" : "unset",
                            height: typeFile?.includes("pdf") ? "100%" : "unset",
                            margin: '0px',
                        },
                       
                        '.rpv-core__inner-container': {
                            paddingBottom: '30px !important',
                        },
                    }}
                    open={!!fileData}
                >
                    <DialogContent>
                        {
                            renderContentPreview()
                        }
                    </DialogContent>
                </Dialog>
            )}
            <form
                onSubmit={handleSubmit(onCreate)}
                className="container-project-create-detail d-flex flex-row"
            >
                <Box sx={{
                        '.select-search': {
                            '.is-selected': {
                                backgroundColor: '#f2f9fc !important',
                                color: '#000 !important',
                            },
                        },
                        '.select-search__input': {
                            boxShadow: 'none !important',
                            borderColor: '#CDD1D5 !important',
                        },
                        '.select-search__option:hover': {
                            backgroundColor: '#f2f9fc !important',
                            background: '#f2f9fc !important',
                        },
                    }} className="project-create-content d-flex flex-column">
                    <div
                        className="create-project-button1 d-flex flex-row"
                        style={{ height: '53px' }}
                    >
                        <label className="create-project-title1">
                            案件新規登録
                        </label>
                        {/* <label>adasfad</label> */}
                    </div>
                    {/* <div className="search-workers-row auto-height d-flex flex-row" style={{marginTop: "10px"}}> */}
                    {errors.request_validation && (
                        <span className="error">
                            {errors.request_validation.message}
                        </span>
                    )}
                    <div className="create-project-input d-flex flex-column">
                        <div className="input-add-user d-flex flex-column">
                            <p style={{ marginTop: 0 }}>案件名</p>
                            <input
                                autoComplete="off"
                                type="text"
                                className="dropdown-create-user2"
                                {...register(
                                    'name',
                                    createProjectValidation(t).name(),
                                )}
                                onChange={(e) => setIsInvalidName(e.target.value?.length > 255 ? true : false)}
                            ></input>
                            {errors.name && !isInvalidName && (
                                <span className="error">
                                    {errors.name.message}
                                </span>
                            )}
                            {isInvalidName && (
                                <span className="error">255文字以内で入力してください。</span>
                            )}
                        </div>
                    </div>

                    <div className="create-project-input d-flex flex-column">
                        <div className="input-add-user d-flex flex-column">
                            <p style={{ marginTop: 0 }}>画像</p>
                            {imageFile && (
                                <div
                                    className="uploadedFilePreviewContainer"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    <div
                                        className="imagePreviewWrapper"
                                        style={{
                                            position: 'relative',
                                            width: '200px',
                                            height: '200px',
                                            background: '#F4F4F4',
                                            borderRadius: '10px',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <img
                                            src={imageFile.path}
                                            alt="uploaded project image file"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                        <img
                                            src={xCircle}
                                            alt="delete icon"
                                            style={{
                                                position: 'absolute',
                                                top: '10px',
                                                right: '10px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                URL.revokeObjectURL(
                                                    imageFile.path,
                                                );
                                                setImageFile(null);
                                            }}
                                        />
                                    </div>
                                    <Button
                                        component="label"
                                        className="uploadImageFileBtn"
                                        style={{
                                            backgroundColor: '#215493',
                                            color: '#FFFFFF',
                                            fontFamily:
                                                'Zen Kaku Gothic Antique',
                                            fontWeight: '500',
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            padding: '10px 18px',
                                            width: '130px',
                                            height: '36px',
                                            borderRadius: '6px',
                                        }}
                                    >
                                        <img
                                            src={arrowsCounterClockwise}
                                            alt="upload icon"
                                            style={{ marginRight: '7px' }}
                                        />
                                        <span>画像編集</span>
                                        <input
                                            type="file"
                                            hidden
                                            style={{
                                                display: 'none',
                                            }}
                                            accept="image/*"
                                            onChange={(e) => {
                                                URL.revokeObjectURL(
                                                    imageFile.path,
                                                );
                                                handleUploadFile(
                                                    e.target.files,
                                                );
                                            }}
                                        />
                                    </Button>
                                </div>
                            )}
                            {!imageFile && (
                                <Button
                                    component="label"
                                    className="uploadImageFileBtn"
                                    style={{
                                        backgroundColor: '#215493',
                                        color: '#FFFFFF',
                                        fontFamily: 'Zen Kaku Gothic Antique',
                                        fontWeight: '500',
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        padding: '10px 18px',
                                        width: '200px',
                                        height: '36px',
                                        borderRadius: '6px',
                                    }}
                                >
                                    <img
                                        src={uploadSimple}
                                        alt="upload icon"
                                        style={{ marginRight: '7px' }}
                                    />
                                    <span>画像アップロード</span>
                                    <input
                                        type="file"
                                        hidden
                                        style={{
                                            display: 'none',
                                        }}
                                        accept="image/*"
                                        onChange={(e) =>
                                            handleUploadFile(e.target.files)
                                        }
                                    />
                                </Button>
                            )}
                        </div>
                    </div>
                   
                    <div className="flex flex-col mt-[14px]">
                        <label>ファイル</label>
                        <Button
                            component="label"
                            className="uploadImageFileBtn"
                            style={{
                                width: 'fit-content',
                                backgroundColor: '#215493',
                                color: '#FFFFFF',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '24px',
                                padding: '10px 18px',
                                height: '36px',
                                borderRadius: '6px',
                            }}
                        >
                            <img
                                src={uploadSimple}
                                alt="upload icon"
                                style={{ marginRight: '7px' }}
                            />
                            <span>ファイルアップロード</span>
                            <input
                                type="file"
                                name="files"
                                hidden
                                style={{
                                    display: 'none',
                                }}
                                onChange={handleFileUpload}
                                accept="image/png, image/gif, image/jpeg, image/webp, image/jpg, application/pdf"
                                multiple
                                onClick={(e) => (e.target as HTMLInputElement).value = ""}
                            />
                        </Button>
                        {newFiles && Array.from(newFiles)?.map((item: any, index: number) => (
                            <div key={index} className='flex gap-x-[4px]'>
                                <p className="mb-0">{item?.name}</p>
                                <p className="text-[#195192] text-[14px] font-[500] cursor-pointer underline mb-0" onClick={() => { handleViewFile(item) }}>プレビュー</p>
                                <DeleteIcon className='text-[#d11a2a] cursor-pointer' onClick={() => {handleDeleteFile(item?.signed_id, newFiles, item?.lastModified, index)}}></DeleteIcon>
                            </div>
                        ))}
                        <span className="error">{showErrorFileSize}</span>
                        <span className="error">{errorTypeFile}</span>
                    </div>   

                    <div className="create-project-input d-flex flex-column">
                        <div className="input-add-user d-flex flex-column">
                            <p style={{ marginTop: 0 }}>業種</p>
                            <Controller
                                name="business_type"
                                control={control}
                                render={({ field }) => (
                                    <Dropdown
                                        className="dropdown-create-user1 height36"
                                        controlClassName="dropdown-control-user height36"
                                        options={businessTypes.filter(
                                            (v) => v.label !== '不動産',
                                        )}
                                        {...register(
                                            'business_type',
                                            createProjectValidation(
                                                t,
                                            ).business_type(),
                                        )}
                                        {...field}
                                        placeholder=""
                                    />
                                )}
                            />
                            {errors.business_type && (
                                <span className="error">
                                    {errors.business_type.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div
                        className="create-project-input d-flex flex-column"
                        style={{ position: 'relative' }}
                    >
                        <div className="input-add-user d-flex flex-column">
                            <p style={{ marginTop: 0 }}>顧客</p>
                            <Controller
                                name="client_company_id"
                                control={control}
                                render={({ field }) => (
                                    <SelectSearch
                                        options={listCompanies}
                                        search
                                        filterOptions={handleFilter}
                                        {...register(
                                            'client_company_id',
                                            createProjectValidation(
                                                t,
                                            ).client_company_id(),
                                        )}
                                        {...field}
                                        emptyMessage={'該当なし'}
                                        placeholder=""
                                    />
                                )}
                            />
                            {errors.client_company_id && (
                                <span className="error">
                                    {errors.client_company_id.message}
                                </span>
                            )}
                        </div>
                        <button
                            className="create-project-button2 d-flex mt-[8px] !bg-[#215493] flex-row button-create-client"
                            onClick={() => navigate('/companies/create')}
                        >
                            新規顧客登録
                        </button>
                    </div>

                    {showCooperativeField && (
                        <div className="create-project-input d-flex flex-column">
                            <div className="input-add-user d-flex flex-column">
                                <p style={{ marginTop: 0 }}>協力会社</p>
                                <Controller
                                    name="cooperative_company_ids"
                                    control={control}
                                    render={({ field: { value } }) => {
                                        if (!value) return <></>;
                                        return (
                                            <Autocomplete
                                                multiple
                                                value={value}
                                                options={listCooperatives}
                                                getOptionLabel={(option: any) =>
                                                    option.label || option.value
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value,
                                                ) =>
                                                    option.value === value.value
                                                }
                                                defaultValue={[]}
                                                classes={{
                                                    root: 'project-create-multiple-select-style custom-styles',
                                                    input: 'project-create-multiple-select-style',
                                                }}
                                                onChange={(event, newValue) => {
                                                    setValue(
                                                        'cooperative_company_ids',
                                                        newValue,
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className="autocomplete-custom-textfield"
                                                    />
                                                )}
                                                noOptionsText="該当なし"
                                            />
                                        );
                                    }}
                                />
                                {errors.cooperative_company_ids && (
                                    <span className="error">
                                        {errors.cooperative_company_ids.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="create-project-input d-flex flex-column">
                        <div className="input-add-user d-flex flex-column">
                            <p style={{ marginTop: 0 }}>工期予定</p>
                            <div className="datepicker-wrap">
                                <div className="target-datepicker-wrap d-flex flex-row">
                                    <div
                                        className="input-date"
                                        style={{ position: 'relative' }}
                                    >
                                        <Controller
                                            name="start_at"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    selected={startDate}
                                                    {...register(
                                                        'start_at',
                                                        // createProjectValidation(t).start_at()
                                                    )}
                                                    {...field}
                                                    ref={startDateRef}
                                                    onChange={(date) => {
                                                        field?.onChange(date);
                                                        handleStartAtChange(
                                                            date,
                                                        );
                                                    }}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsStart
                                                    // required={true}
                                                    maxDate={endDate}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    locale="ja"
                                                />
                                            )}
                                        />
                                        <img
                                            onClick={() =>
                                                startDateRef?.current?.handleFocus()
                                            }
                                            className="input-date-img cursor-pointer"
                                            width={'16px'}
                                            height={'16px'}
                                            src={calendarIcon}
                                        ></img>
                                    </div>
                                    <span className="symbol">~</span>
                                    <div
                                        className="input-date"
                                        style={{ position: 'relative' }}
                                    >
                                        <Controller
                                            name="end_at"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    selected={endDate}
                                                    {...register(
                                                        'end_at',
                                                        // createProjectValidation(t).end_at()
                                                    )}
                                                    {...field}
                                                    ref={endDateRef}
                                                    onChange={(date) => {
                                                        field?.onChange(date);
                                                        handleEndAtChange(date);
                                                    }}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsEnd
                                                    // required={true}
                                                    minDate={startDate}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    locale="ja"
                                                />
                                            )}
                                        />
                                        <img
                                            onClick={() =>
                                                endDateRef?.current?.handleFocus()
                                            }
                                            className="input-date-img cursor-pointer"
                                            width={'16px'}
                                            height={'16px'}
                                            src={calendarIcon}
                                        ></img>
                                    </div>
                                </div>
                            </div>
                            {(errors.start_at || errors.end_at) && (
                                <span className="error">
                                    {errors.start_at?.message ||
                                        errors.end_at?.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="create-project-input d-flex flex-column">
                        <div className="input-add-user d-flex flex-column">
                            <p style={{ marginTop: 0 }}>見積担当</p>
                            <Controller
                                name="estimate_assigned_worker_id"
                                control={control}
                                render={({ field }) => (
                                    // <Dropdown
                                    //     className='dropdown-create-user1 height36'
                                    //     controlClassName='dropdown-control-user height36'
                                    //     options={listWorkers}
                                    //     {...register('estimate_assigned_worker_id', createProjectValidation(t).estimate_assigned_worker_id())}
                                    //     {...field}
                                    //     placeholder=""
                                    // />}
                                    <SelectSearch
                                        options={listWorkers}
                                        search
                                        filterOptions={handleFilter}
                                        {...register(
                                            'estimate_assigned_worker_id',
                                            // createProjectValidation(t).estimate_assigned_worker_id()
                                        )}
                                        {...field}
                                        emptyMessage={'該当なし'}
                                        placeholder=""
                                    />
                                )}
                            />
                            {errors.estimate_assigned_worker_id && (
                                <span className="error">
                                    {errors.estimate_assigned_worker_id.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="create-project-input d-flex flex-column">
                        <div className="input-add-user d-flex flex-column">
                            <p style={{ marginTop: 0 }}>すごろく閲覧権限</p>
                            <Controller
                                name="assigned_project_sugoroku_workers_attributes"
                                control={control}
                                render={({ field: { value } }) => (
                                    <Autocomplete
                                        multiple
                                        value={value}
                                        options={listWorkers}
                                        getOptionLabel={(option: any) =>
                                            option.label || option.value
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        defaultValue={[]}
                                        classes={{
                                            root: 'project-create-multiple-select-style custom-styles',
                                            input: 'project-create-multiple-select-style',
                                        }}
                                        onChange={(event, newValue) => {
                                            setValue(
                                                'assigned_project_sugoroku_workers_attributes',
                                                newValue,
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className="autocomplete-custom-textfield"
                                            />
                                        )}
                                        noOptionsText="該当なし"
                                    />
                                )}
                            />
                            {errors.manager_worker_id && (
                                <span className="error">
                                    {errors.manager_worker_id.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="create-project-input d-flex flex-column">
                        <div className="input-add-user d-flex flex-column">
                            <p style={{ marginTop: 0 }}>郵便番号</p>
                            <input
                                {...register(
                                    'location_zipcode',
                                    // createProjectValidation(t).location_zipcode()
                                )}
                                autoComplete="off"
                                type="text"
                                className="dropdown-create-user2"
                            ></input>
                            {errors.location_zipcode && (
                                <span className="error">
                                    {errors.location_zipcode.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="create-project-input d-flex flex-column">
                        <div className="input-add-user d-flex flex-column">
                            <p style={{ marginTop: 0 }}>住所</p>
                            <input
                                {...register(
                                    'location_address',
                                    // createProjectValidation(t).location_address()
                                )}
                                autoComplete="off"
                                type="text"
                                className="dropdown-create-user2"
                            ></input>
                            {errors.location_address && (
                                <span className="error">
                                    {errors.location_address.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="create-project-input d-flex flex-column">
                        <div className="input-add-user d-flex flex-column">
                            <p style={{ marginTop: 0 }}>担当社員</p>
                            <Controller
                                name="assigned_project_workers_attributes"
                                control={control}
                                render={({ field: { value } }) => (
                                    <Autocomplete
                                        multiple
                                        value={value}
                                        options={listWorkers}
                                        getOptionLabel={(option: any) =>
                                            option.label || option.value
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        defaultValue={[]}
                                        classes={{
                                            root: 'project-create-multiple-select-style custom-styles',
                                            input: 'project-create-multiple-select-style',
                                        }}
                                        onChange={(event, newValue) => {
                                            setValue(
                                                'assigned_project_workers_attributes',
                                                newValue,
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className="autocomplete-custom-textfield"
                                            />
                                        )}
                                        noOptionsText="該当なし"
                                    />
                                )}
                            />
                            {errors.manager_worker_id && (
                                <span className="error">
                                    {errors.manager_worker_id.message}
                                </span>
                            )}
                        </div>
                    </div>
                    {/* <div className="create-project-input d-flex flex-column">
                    <div className='input-add-user d-flex flex-column'>
                        <p style={{ marginTop: 0 }}>協力会社</p>
                        <Dropdown
                            className='dropdown-create-user1'
                            controlClassName='dropdown-control-user'
                            options={listCooperativeCompanies}
                            // onChange={onSelect} 
                            // value={department} 
                            placeholder="" />
                    </div>
                </div> */}
                    <div className="create-project-input d-flex flex-column">
                        <div className="input-add-user d-flex flex-column">
                            <p style={{ marginTop: 0 }}>管理者</p>
                            <Controller
                                name="manager_worker_id"
                                control={control}
                                render={({ field }) => (
                                    // <Dropdown
                                    //     className='dropdown-create-user1 height36'
                                    //     controlClassName='dropdown-control-user height36'
                                    //     options={listWorkers}
                                    //     {...register('manager_worker_id', createProjectValidation(t).manager_worker_id())}
                                    //     {...field}
                                    //     placeholder=""
                                    // />}
                                    <SelectSearch
                                        options={listWorkers}
                                        search
                                        filterOptions={handleFilter}
                                        {...register(
                                            'manager_worker_id',
                                            // createProjectValidation(t).manager_worker_id()
                                        )}
                                        {...field}
                                        emptyMessage={'該当なし'}
                                        placeholder=""
                                    />
                                )}
                            />
                            {errors.manager_worker_id && (
                                <span className="error">
                                    {errors.manager_worker_id.message}
                                </span>
                            )}
                        </div>
                    </div>
                    {/* </div> */}
                    <div
                        className="input-add-user d-flex flex-column"
                        style={{ marginTop: '12px' }}
                    >
                        <p style={{ marginTop: 0 }}>備考</p>
                        <textarea
                            autoComplete="off"
                            className="dropdown-create-user2"
                            {...register('note')}
                        ></textarea>
                    </div>

                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{
                            marginTop: '14px',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row"
                            style={{ width: '167.5px' }}
                            onClick={() => navigate('/projects')}
                        >
                            戻る
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row"
                            style={{ marginLeft: '10px', width: '167.5px' }}
                            type="submit"
                        >
                            登録
                        </button>
                    </div>
                </Box>
            </form>
        </ProjectCreateStyles>
    );
};

export default ProjectCreate;
