export const EDIT_SUGOROKU_MODE = 'edit';
export const VIEW_SUGOROKU_MODE = 'view';

export const ListItems = [
    {
      icon: '/img/topSP/icon-todo.svg',
      name: 'TODO<br/>リスト',
      route: '/sp/todo',
    },
    {
      icon: '/img/topSP/icon-calendar.svg',
      name: 'カレンダー',
      route: '/sp/calendar',
    },
    {
        icon: '/img/topSP/icon-project.svg',
        name: '案件一覧',
        route: '/sp/projects',
    },
    {
        icon: '/img/topSP/icon-chat.svg',
        name: '社内<br/>メッセージ',
        route: '/sp/chats',
    },
    {
        icon: '/img/topSP/icon-job.svg',
        name: '契約外作業<br/>確認書',
        route: '/sp/jobs',
    },
    {
        icon: '/img/topSP/icon-report.svg',
        name: '日報',
        route: '/sp/projects_list',
    },
    {
      icon: '/img/topSP/ic-p2.svg',
      name: '工程',
      route: '/sp/projects_list_plan',
    },
    {
        icon: '/img/topSP/icon-target.svg',
        name: '目標',
        route: '/sp/target',
    },
    {
        icon: '/img/topSP/icon-car.svg',
        name: '車両点<br/>検簿',
        route: '/sp/inspection-records',
    },
    {
        icon: '/img/topSP/icon-alarm.svg',
        name: '出退勤',
        route: '/sp/timekeeping',
    },
    {
      icon: '/img/topSP/icon-stocknote.svg',
      name: '納品書一覧',
      route: '/sp/stock_notes',
  },
  {
    icon: '/img/topSP/icon-pr.svg',
    name: '経費要望',
    route: '/sp/purchase-request',
  },
  {
    icon: '/img/topSP/icon-ojt.svg',
    name: '各種<br/>マニュアル',
    route: '/sp/internal-ojt',
  },
  {
    icon: '/img/topSP/icon-estimate3.png',
    name: '見積書一覧',
    route: '/sp/estimates',
  },

];

export const MONTHS_IN_YEAR = [
    {
      id: '1',
      name: '1月',
    },
    {
      id: '2',
      name: '2月',
    },
    {
      id: '3',
      name: '3月',
    },
    {
      id: '4',
      name: '4月',
    },
    {
      id: '5',
      name: '5月',
    },
    {
      id: '6',
      name: '6月',
    },
    {
      id: '7',
      name: '7月',
    },
    {
      id: '8',
      name: '8月',
    },
    {
      id: '9',
      name: '9月',
    },
    {
      id: '10',
      name: '10月',
    },
    {
      id: '11',
      name: '11月',
    },
    {
      id: '12',
      name: '12月',
    },
  ];

export const WEEKS_IN_MONTH = [
    {
      id: '1',
      name: '第1週',
    },
    {
      id: '2',
      name: '第2週',
    },
    {
      id: '3',
      name: '第3週',
    },
    {
      id: '4',
      name: '第4週',
    },
    {
      id: '5',
      name: '第5週',
    },
  ];

export const BUSINESS_TYPE_SUGORUKU = '解体';